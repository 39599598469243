<template>
    <app-dialog class="dialog-adding-product"
        v-if="show"
        v-model="show"

        :max-width="maxWidth"

        @closes="closes"
    >
        <template #head>
            <h2 class="heading">Add product</h2>
        </template>

        <template #form>
            <div>
                <app-dropdown-select
                    v-model="product_type"

                    :options="products_adding_types"

                    label="Product Type"

                    key-value="key"
                    key-title="title"
                />
            </div>
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="!product_type">Сonfirm</button>
            <button class="btn btn-cancel" @click.prevent="close">Cancel</button>
        </template>
    </app-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'

import appDropdownSelect from '@/components/app-dropdown-select'

export default {
    props: {
        value: { required: true },

        maxWidth: { type: [ Number, String ], default: 610 },
    },

    components: {
        appDialog,

        appDropdownSelect,
    },

    data() {
        return {
            product_type: null,
        }
    },

    methods: {
        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        confirm() {
            this.close()
            this.$router.push({ name: 'product-creating', params: { type: this.product_type } })
        },
    },

    computed: {
        ...mapGetters([
            'products_adding_types'
        ]),

        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>
