<template>
    <div class="page-products">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-search-box
                        v-model="filter.word"

                        label="Search"

                        :loading="loading"

                        :debounce="1000"

                        :show-clear-button="true"

                        @search="onSearch"
                    />

                    <app-table
                        class="catalogue-table"

                        :cols="cols"
                        :rows="list"

                        title="Product Catalogue"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onProductsSortChange"

                        :clickable="true"
                        @row-click="openDetails"
                        @row-click-mobile="openDetails"

                        :loading="loading"
                    >
                        <template #header-actions>
                            <button class="btn btn-small btn-primary" @click="dialogs.adding.show = true" v-if="with_products_adding">Add Product</button>
                        </template>
                    </app-table>

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>

        <dialog-adding-product
            v-if="dialogs.adding.show"
            v-model="dialogs.adding.show"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appSearchBox from '@/components/app-search-box'

import dialogAddingProduct from './dialogs/dialog-adding-product'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appTable,
        appPagination,
        appSearchBox,

        dialogAddingProduct,
    },

    data() {
        return {
            list: [],

            filter: {
                word: '',
                previous: '',

                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Name',        title: 'Name'        },
                { field: 'Description', title: 'Description' },
                { field: 'ProductType', title: 'ProductType' },
            ],

            cols: [
                { key: 'Name',        title: 'Name',         sort: { field: 'Name'        }, highlight: true },
                { key: 'Description', title: 'Description',  sort: { field: 'Description' },                 },
                { key: 'ProductType', title: 'Product Type', sort: { field: 'ProductType' },                 },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                adding: {
                    show: false,
                },
            },

            loading: false,
        }
    },

    methods: {
        init(to, from) {
            this.getProducts(to.params.page, this.filter)
        },

        getProducts(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,

                ...filter,
            }

            let params = {
                SPID: this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':   filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,

                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber'],
                }

                this.filter.word = word
                this.filter.previous = word
            }

            return this.$store.dispatch('api_product_catalog/FindProductCatalogEntriesPaginated', params).then(response => {
                this.list = response.ProductCatalogEntries.filter(product => !product.DeletedAtNanos)

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                this.$store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                this.list = []

                this.loading = false
            })
        },

        openDetails(product) {
            this.$router.push({ name: 'product', params: { uuid: product.UUID } })
        },

        /**
         * Filtering
         */
        onProductsSortChange(sort) {
            this.filter.sort = sort
            this.getProducts()
        },

        onPageChange(page) {
            this.getProducts(page)
        },

        onSearch() {
            this.getProducts()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'products_adding_types',
        ]),

        with_products_adding() {
            return Object.keys(this.products_adding_types).length
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },
}
</script>

<style lang="scss">
.page-products {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-search-box {
        margin-bottom: 30px;
    }

    .catalogue-table {
        @include table-cols-width((250px, 350px, 80px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-products {
        .app-add-provider {
            margin-top: 30px;
        }

        .catalogue-table {
            @include table-cols-width((140px, 180px, 80px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-products {
        .app-search-box {
            margin-bottom: 15px;
        }

        .catalogue-table {
            @include table-cols-width-mobile((70px, 205px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-add-provider {
            margin-top: 15px;
        }
    }
}
</style>